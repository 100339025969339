import React from "react";
import { styled } from "styled-components";
import dynamic from "next/dynamic";
const CourseStatusButton = dynamic(() => import("./CourseStatusButton"));
const DownloadBrochureButton = dynamic(() =>
  import("./DownloadBrochureButton")
);
const UpgradePlanButton = dynamic(() => import("./UpgradePlanButton"));
import UserCourseStatusEnum from "@Enums/UserCourseStatusEnum";
import { useRouter } from "next/router";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import useUserCourseStatusAction from "@/store/actions/userCourseStatusAction";
import {
  ScholarshipTypeEnum,
  UserScholarshipStatusEnum,
} from "@/outscal-commons-frontend/Enums";
import Link from "next/link";
import useScroll from "@/hooks/useScroll";
import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: ${(p) => p.actionButtonsGap};
  padding-right: 1rem;
  flex-direction: ${(p) => p.actionButtonsDirection};
  align-items: baseline;

  @media (max-width: 500px) {
    align-items: center;
    flex-direction: column;
    padding-right: 0px;
  }
`;

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.colors.themeBlue};
  font-size: ${(p) => p.theme.fontSize.lg};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  text-decoration: underline;
  text-align: center;
`;
const CourseActionButtons = ({
  showPreviewButton,
  showCourseStatusMeta = true,
  StatusButton,
  PreviewButton,
  UpgradeButton,
  actionButtonsDirection = "row",
  actionButtonsGap = "16px",
  proBgColor,
  ctaLinkWidth,
  linkColor,
  ctaBtnsMiddleText,
  ctaBtnsMiddleLink,
}) => {
  const router = useRouter();
  const { userCourseStatus } = useUserCourseStatusAction();
  const validStatuses = [
    UserCourseStatusEnum.getValue("access-granted"),
    UserCourseStatusEnum.getValue("started"),
    UserCourseStatusEnum.getValue("started-pro"),
    UserCourseStatusEnum.getValue("access-granted-pro"),
  ];

  const { scrollToSectionById } = useScroll();

  const checkToShowPreviewButton = () => {
    if (UserCourseService.instance.course.slug == "advanced-cpp") {
      return false;
    }
    if (
      showPreviewButton &&
      userCourseStatus.previewLink &&
      !validStatuses.includes(userCourseStatus.currentStatus) &&
      !router.asPath.includes("/course-listing")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const CTATextWrapper = ctaBtnsMiddleLink ? Link : "p";
  return (
    <Root>
      <ActionButtons
        actionButtonsDirection={actionButtonsDirection}
        actionButtonsGap={actionButtonsGap}
      >
        <CourseStatusButton CTA={StatusButton} proBgColor={proBgColor} />

        {ctaBtnsMiddleText && (
          <CTATextWrapper
            href={ctaBtnsMiddleLink || undefined}
            style={{
              color: "white",
              marginInline: "auto",
              textAlign: "center",
              fontSize: "var(--font-sm)",
              textDecoration: "underline",
              textUnderlineOffset: "2px",
            }}
          >
            {ctaBtnsMiddleText}
          </CTATextWrapper>
        )}

        {checkToShowPreviewButton() && (
          <DownloadBrochureButton
            PreviewCTA={PreviewButton}
            linkColor={linkColor}
            ctaLinkWidth={ctaLinkWidth}
          />
        )}
        {UserCourseService.instance.userCourseController.getUpgradButtonText(
          userCourseStatus.coursePermissions
        ) && (
          <UpgradePlanButton
            CTA={UpgradeButton}
            buttonText={UserCourseService.instance.userCourseController.getUpgradButtonText(
              userCourseStatus.coursePermissions
            )}
            onClick={() => {
              scrollToSectionById("pricing");
            }}
          />
        )}
      </ActionButtons>
      {userCourseStatus?.currentScholarshipStatus ==
        UserScholarshipStatusEnum.getValue("application-started") &&
        showCourseStatusMeta &&
        userCourseStatus?.currentStatus ===
          UserCourseStatusEnum.getValue("waitlisted") && (
          <StyledLink
            href={`/scholarship/${ScholarshipTypeEnum.getEnum(
              userCourseStatus?.currentScholarshipType
            )}`}
            data-analytics={[UIElements.LINK, "complete-scholarship-steps"]}
          >
            Complete Scholarship Steps
          </StyledLink>
        )}
    </Root>
  );
};

export default CourseActionButtons;
