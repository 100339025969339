import React from "react";
import DefaultCTAsView from "./DefaultCTAs/DefaultCTAs.view";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
import CourseDetailsPageManager from "@/Logic/ClientLogic/Services/PageServices/CourseDetailsPageManager";
import CourseActionButtons from "@/Components/CourseAction/CourseActionButtons";
import { PreviewButton, StatusButton, UpgradeButton } from "./CourseCTAs.style";
import useUserCourseStatusAction from "@/store/actions/userCourseStatusAction";
import { UserCourseStatusEnum } from "@/outscal-commons-frontend/Enums";
import styles from "./HaloSctn.module.css";
import { useRouter } from "next/router";
import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";
import useAuthActions from "@/store/actions/authAction";

//currently this is getting if condition according to course detailing page and condition keeps getting added - should be only course specific

const CTAsController = ({
  cta1,
  cta2,
  section_id,
  ctaBtnsMiddleText,
  ctaBtnsMiddleLink,
  useDefaultCta,
  ctaBtnsHeading,
  ctaBtnsSubheading,
  defaultCtaVariant = "transparent",
}) => {
  const router = useRouter();

  const isCourseDetailsPage =
    ClientAppManager.instance.pageManager instanceof CourseDetailsPageManager;

  const { userCourseStatus } = useUserCourseStatusAction();

  const handleLinkClick = (event, cta) => {
    if (isCourseDetailsPage && cta?.link?.includes("/payment/")) {
      sessionStorage.setItem("paymentRedirectionUrl", router.asPath);
    } else if (
      isCourseDetailsPage &&
      cta?.link?.includes("/modules") &&
      UserCourseService.instance?.courseFromStrapi?.firstMaterialUrl
    ) {
      event.preventDefault();
      router.push(
        UserCourseService.instance?.courseFromStrapi?.firstMaterialUrl
      );
    }
  };
  const showCourseActionButton =
    (isCourseDetailsPage &&
      UserCourseService?.instance?.courseFromStrapi?.status !== "COMING-SOON" &&
      (userCourseStatus?.currentStatus ==
        UserCourseStatusEnum.getValue("access-granted") ||
        userCourseStatus?.currentStatus ==
          UserCourseStatusEnum.getValue("started"))) ||
    (isCourseDetailsPage && !useDefaultCta);
  return (
    <div
      userCourseStatus={userCourseStatus}
      className={`${styles["cta-wrapper"]}  ${
        styles[showCourseActionButton ? "transparent" : defaultCtaVariant]
      }`}
    >
      {showCourseActionButton ? (
        <CourseActionButtons
          userCourseStatus={userCourseStatus}
          showPreviewButton={true}
          actionButtonsDirection="column"
          actionButtonsGap="8px"
          StatusButton={StatusButton}
          proBgColor={"black"}
          PreviewButton={PreviewButton}
          linkColor={"#f3ca20"}
          ctaLinkWidth={"100%"}
          UpgradeButton={UpgradeButton}
          ctaBtnsMiddleText={ctaBtnsMiddleText}
          ctaBtnsMiddleLink={ctaBtnsMiddleLink}
          ctaBtnsHeading={ctaBtnsHeading}
          ctaBtnsSubheading={ctaBtnsSubheading}
        />
      ) : (
        <DefaultCTAsView
          cta1={cta1}
          cta2={cta2}
          section_id={section_id}
          ctaBtnsMiddleText={ctaBtnsMiddleText}
          ctaBtnsMiddleLink={ctaBtnsMiddleLink}
          ctaBtnsHeading={ctaBtnsHeading}
          ctaBtnsSubheading={ctaBtnsSubheading}
          variant={defaultCtaVariant}
          handleLinkClick={handleLinkClick}
        />
      )}
    </div>
  );
};

export default CTAsController;
