import BasePageManager from "./BasePageManager";
import { CoursePageData } from "../Service.types";
import ClientAppManager from "../ClientAppManager";

class CourseDetailsPageManager extends BasePageManager<
  CoursePageData,
  ClientAppManager
> {}

export default CourseDetailsPageManager;
