import React from "react";
import styles from "./DefaultCTAs.module.css";
import dynamic from "next/dynamic";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";
import Link from "next/link";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import DefaultCTAsModel from "./DefaultCTAs.model";
import { appendAsQueryParam, removeHashFromUrl } from "@/utils/urlHelper";
import { useRouter } from "next/router";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
const ProTag = dynamic(() =>
  import("@/mvcComponents/Commons/ProTag/ProTag.controller")
);

const getVariantStyles = (variant) =>
  DefaultCTAsModel.variantData[variant] ||
  DefaultCTAsModel.variantData["transparent"];

const DefaultCTAsView = ({
  cta1,
  cta2,
  section_id,
  ctaBtnsMiddleText,
  ctaBtnsMiddleLink,
  ctaBtnsHeading,
  ctaBtnsSubheading,
  variant = "transparent",
  handleLinkClick = () => {},
}) => {
  const router = useRouter();
  const { textColor, bgColor } = getVariantStyles(variant);
  const CTATextWrapper = ctaBtnsMiddleLink ? Link : "p";

  return (
    <div
      className={`${styles["container"]} ${styles[variant]}`}
      style={{ backgroundColor: bgColor }}
    >
      {ctaBtnsHeading && (
        <p style={{ color: textColor }} className={styles["cta-btns-heading"]}>
          {ctaBtnsHeading}
        </p>
      )}
      {ctaBtnsSubheading && (
        <p
          style={{ color: textColor }}
          className={styles["cta-btns-subheading"]}
        >
          {ctaBtnsSubheading}
        </p>
      )}
      {cta1 && cta1?.text && (
        <Link
          replace
          data-analytics={[UIElements.LINK, `${section_id}-cta1`]}
          className={`tf-btn add-to-cart ${styles["cta-1"]} ${styles[variant]}`}
          href={`${removeHashFromUrl(router.asPath)}${appendAsQueryParam(
            router.asPath,
            cta1.link
          )}`}
          onClick={(e) => {
            ClientAppManager.instance.analyticsService.captureUserEvent({
              ShownInterest: "HALO-SECTION",
            });
            handleLinkClick(e, cta1);
          }}
        >
          {cta1.icon && <DynamicIcon icon={cta1.icon} />}
          {cta1.text}
        </Link>
      )}

      {ctaBtnsMiddleText && (
        <CTATextWrapper
          href={ctaBtnsMiddleLink || undefined}
          style={{
            color: "var(--white)",
            marginInline: "auto",
            textAlign: "center",
            fontSize: "var(--font-sm)",
            textDecoration: ctaBtnsMiddleLink ? "underline" : "none",
            textUnderlineOffset: "2px",
          }}
        >
          {ctaBtnsMiddleText}
        </CTATextWrapper>
      )}

      {cta2 && cta2?.text && (
        <Link
          data-analytics={[UIElements.LINK, `${section_id}-cta2`]}
          className={`tf-btn buy-now ${styles["cta-2"]} ${styles[variant]}`}
          href={cta2.link}
          onClick={(e) => {
            ClientAppManager.instance.analyticsService.captureUserEvent({
              ShownInterest: "HALO-SECTION",
            });
            handleLinkClick(e, cta2);
          }}
        >
          {cta2.text}
          <DynamicIcon icon={cta2.icon} />
        </Link>
      )}
    </div>
  );
};

export default DefaultCTAsView;
