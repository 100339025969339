import styles from "./DefaultCTAs.module.css";

class DefaultCTAsModel {
  constructor() {
    this.variantData = {
      white: {
        textColor: "black",
        bgColor: "white",
      },
      transparent: {
        textColor: "black",
        bgColor: "transparent",
      },
    };
  }
}

export default new DefaultCTAsModel();
