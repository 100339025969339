import Link from "next/link";
import styled from "styled-components";

const CTA = styled.button`
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    font-weight: ${(p) => p.theme.fontWeight.medium} !important;
    font-size: ${(p) => p.theme.fontSize.md} !important;
`

export const StatusButton = styled(CTA)`
    background-color: var(--theme-yellow);
`



export const PreviewButton = styled(Link)`
    width: 100%;
    padding: 16px !important;
    border-radius: 4px;
    font-weight: ${(p) => p.theme.fontWeight.medium} !important;
    font-size: ${(p) => p.theme.fontSize.md} !important;
    background-color: var(--white);
    text-align:center;
    &:hover{
        color: ${(p) => p.theme.colors.black} !important;
    }
    &:focus{
        color: ${(p) => p.theme.colors.black} !important;
    }
`

export const UpgradeButton = styled(CTA)`
    background-color: var(--white);
`